import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selectedProvider: '',
        selectedAccount: 0,
        selectedAccountIndex: 0,
        // for balance at the top-menu
        balanceWatchToken: { symbol: 'TT', dp: 18, ddp: 6, address: '' },
    },
    mutations: {
        setProvider(state, { selectedAccount, selectedProvider, selectedAccountIndex }) {
            state.selectedAccount = selectedAccount;
            state.selectedProvider = selectedProvider;
            state.selectedAccountIndex = selectedAccountIndex;
        },
        balanceWatchToken(state, { symbol, dp, ddp, address }) {
            state.balanceWatchToken = { symbol, dp, ddp, address };
        },
        setAccount(state, account) {
            state.selectedAccount = account;
        }
    },
    actions: {
        watchTT({ commit }) {
            commit("balanceWatchToken", { symbol: 'TT', dp: 18, ddp: 6, address: '' });
        }
    },
    modules: {}
});
