
import version from "../version.json";
import Vue from "vue";
export default Vue.extend({
    name: "footer-section",
    data(): any {
        return {
            version
        };
    }
});
