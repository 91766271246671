import { render, staticRenderFns } from "./FooterSection.vue?vue&type=template&id=742db80d&scoped=true&"
import script from "./FooterSection.vue?vue&type=script&lang=ts&"
export * from "./FooterSection.vue?vue&type=script&lang=ts&"
import style0 from "./FooterSection.vue?vue&type=style&index=0&id=742db80d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742db80d",
  null
  
)

export default component.exports