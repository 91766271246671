
import { BigNumber } from "ethers";
import { Component, Vue } from "vue-property-decorator";
import ContractMonitoring from "../contracts/contract-monitoring";
import Big from "bignumber.js";
Big.config({ EXPONENTIAL_AT: 256 });

interface BetItem {
    id: string;
    player: string;
    higher: boolean;
    mark: number;
    roll: number;
    multiplier: string;
    bet: string;
    won: boolean;
    profit: string;
}

@Component({})
export default class Home extends Vue {
    monitor: ContractMonitoring | null = null;

    bets: BetItem[] = [];

    beforeDestroy() {
        this.monitor?.contract?.removeAllListeners("BetStatus");
    }

    mounted() {
        this.monitor = new ContractMonitoring("DiceGame");

        this.monitor.contract?.on(
            "BetStatus",
            (
                player: string,
                won: boolean,
                mark: number,
                lower: boolean,
                roll: number,
                value: BigNumber,
                multiplier: BigNumber,
                sent: BigNumber,
                e: any
            ) => {
                const item: BetItem = {
                    id: e.transactionHash,
                    player,
                    higher: !lower,
                    mark: mark,
                    roll: roll,
                    multiplier: new Big(multiplier.toNumber())
                        .div(10000)
                        .decimalPlaces(3)
                        .toString(),
                    bet: new Big(value.toString()).div(1e18).toFormat(3),
                    won,
                    profit: won
                        ? new Big(sent.toString()).div(1e18).toFormat(4)
                        : new Big(-1)
                              .times(value.toString())
                              .div(1e18)
                              .toFormat(4),
                };

                this.bets.unshift(item);
                this.bets.splice(40);
            }
        );
    }
}
