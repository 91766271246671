import Big from "bignumber.js";

export default [
    // {
    //     id: "TT",
    //     gid: 1,
    //     name: "Thunder Token",
    //     url: "https://thundercore.com",
    //     min: new Big(0.001),
    //     dp: 18,
    //     ddp: 6,
    //     address: "",
    // },
    // {
    //     id: "TT-ETH",
    //     gid: 3,
    //     name: "Ethereum TT",
    //     url: "https://thundercore.com",
    //     min: new Big(0.001),
    //     dp: 18,
    //     ddp: 6,
    //     address: "0x6576Bb918709906DcbFDCeae4bB1e6df7C8a1077",
    // },
    // {
    //     id: "TT-WBTC",
    //     gid: 4,
    //     name: "Wrapped BTC TT",
    //     url: "https://thundercore.com",
    //     min: new Big(0.001),
    //     dp: 8,
    //     ddp: 8,
    //     address: "0x18fB0A62f207A2a082cA60aA78F47a1af4985190",
    // },
    {
        id: "SET",
        gid: 5,
        name: "Seriously Token",
        url: "https://playdice.xyz",
        min: new Big(0.001),
        dp: 18,
        ddp: 6,
        address: "0xbb9a3CC287a4060B06257435906E0491305C112C",
    },
    {
        id: "CARE v4",
        gid: 6,
        name: "Careful Token v4",
        url: "https://playdice.xyz",
        min: new Big(0.001),
        dp: 18,
        ddp: 6,
        address: "0x62839205805a96F8b4122d4eCD028999E79FBd27",
    },
    {
        id: "TT-USDT",
        gid: 2,
        name: "Tether TT",
        url: "https://thundercore.com",
        min: new Big(0.001),
        dp: 6,
        ddp: 6,
        address: "0x4f3C8E20942461e2c3Bdd8311AC57B0c222f2b82",
    },
    {
        id: "EGG2",
        gid: 7,
        name: "The EGG2",
        url: "https://eggdex.com",
        min: new Big(0.001),
        dp: 18,
        ddp: 6,
        address: "0x8eD8Ae1703ACb33031cBD464400b57978Fe501aB",
    },
    {
        id: "RISER",
        gid: 8,
        name: "RISER",
        url: "https://riserdefi.com",
        min: new Big(0.0001),
        dp: 18,
        ddp: 6,
        address: "0xC1E7000f379f2247Ae930ba98d5568cd9D0b924b",
    },
    {
        id: "RISK",
        gid: 9,
        name: "RISK",
        url: "https://playdice.xyz/risk",
        min: new Big(0.0001),
        dp: 18,
        ddp: 6,
        address: "0x137222fF76855E3804d7C2B345B2fB1d9d7591Ef",
    }
];