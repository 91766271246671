import Vue from "vue";
import App from "./App.vue";
import ContractMonitoring from './contracts/contract-monitoring';
import router from "./router";
import store from "./store";

import Big from "bignumber.js";
Big.config({EXPONENTIAL_AT: 256});

Vue.config.productionTip = false;

const months = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Ags,Sept,Oct,Nov,Dec'.split(',');

Vue.prototype.$humanDate = function (d: Date) {
  return `${d.getDate().toString().padStart(2, '0')} ${months[d.getMonth()]} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
};

Vue.prototype.$humanCountdown = function (secs: number) {
  let str = ''
  const days = Math.floor(secs / 86400);
  if (days > 0) str = `${days} ${days > 1 ? 'days' : 'day'}`;

  secs -= days * 86400;
  const hours = Math.floor(secs / 3600);
  if (hours > 0) str += (str.length > 0 ? ' ' : '') + (`${hours} ${hours > 1 ? 'hours' : 'hour'}`);

  secs -= hours * 3600;
  const minutes = Math.floor(secs / 60);
  if (minutes > 0) str += (str.length > 0 ? ' ' : '') + (`${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`);

  secs -= minutes * 60;
  if (secs > 0) str += (str.length > 0 ? ' ' : '') + (`${secs} ${secs > 1 ? 'seconds' : 'second'}`);

  return str;
}

Vue.prototype.$humanAddress = function (address: string) {
  return address.substr(0, 8) + '...' + address.substr(-4);
}

window.$notify = function (message: string, type: 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'success' | 'light' = 'info') {
  ($ as any).notify({
    message
  }, {
    type,
    spacing: 5,
    z_index: 9999999,
    animate: {
      enter: 'animate__animated animate__fadeInDown',
      exit: 'animate__animated animate__fadeOutUp'
    },
    template: '<div data-notify="container" class="alert alert-{0}" role="alert">' +
      '<button type="button" aria-hidden="true" class="close ml-2" data-notify="dismiss">×</button>' +
      '<span data-notify="icon"></span> ' +
      '<span data-notify="title">{1}</span> ' +
      '<span data-notify="message">{2}</span>' +
      '<div class="progress" data-notify="progressbar">' +
      '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
      '</div>' +
      '<a href="{3}" target="{4}" data-notify="url"></a>' +
      '</div>'
  });
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
