
import Vue from "vue";
import mix from "../web3-mixin";
import { providers } from "ethers";

export default Vue.extend({
    name: "wrong-network",

    mixins: [mix],

    data(): any {
        return {
            ok: null,
            needWallet: false,
            wrongNetwork: false
        };
    },

    watch: {
        "$store.state.selectedProvider": function() {
            this.checkNetwork();
        }
    },

    mounted() {
        if (this.walletProviders.length == 0) {
            this.needWallet = true;
            this.ok = false;
        }

        this.checkNetwork();
    },

    methods: {
        async checkNetwork() {
            // check network
            if (this.$store.state.selectedProvider) {
                const win: any = window;
                const provider = new providers.Web3Provider(
                    win[this.$store.state.selectedProvider]
                );

                const network = await provider.getNetwork();
                if (network.chainId !== 108) {
                    this.wrongNetwork = true;
                    this.ok = false;
                }
            }
        }
    }
});
