<template lang="pug">
div
    div(v-if="wallets.length > 0")
        p Login Using
    .spinner-border(v-if="wallets.length == 0 && detectingWallet === true")
        span.sr-only Detecting wallet...
    .p-2(v-if="wallets.length == 0 && detectingWallet === false")
        span.text-danger No Wallet found.
    .list-group
        a.list-group-item.list-group-item-action(
            v-for="wallet in wallets",
            :key="wallet.provider",
            @click="login(wallet.provider)"
        )
            button.btn.btn-primary.d-block {{ translateProvider(wallet.provider) }}
</template>

<script>
import web3mixin from "../web3-mixin";

export default {
    name: "login",
    mixins: [web3mixin],
    methods: {
        async login(provider) {
            const w = this.wallets.filter(v => v.provider == provider);
            const wallet = w[0];

            if (!wallet.accounts || wallet.accounts.length == 0) {
                try {
                    if (await this.loginWeb3(wallet.provider))
                        location.reload();
                } catch (error) {
                    window.$notify("login web3 failed", "danger");
                }
            } else {
                this.setActiveProvider(provider, 0);
            }
        }
    }
};
</script>