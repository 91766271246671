import { Contract } from 'ethers';
import { providers } from 'ethers';
import contractAddresses from "./contract-addresses.json";
import erc20 from "./erc20.json";
import classicDice from "./classic-dice.json";
import thunderNetwork from '@/thunder-network';

export default class ContractMonitoring {
    provider: providers.JsonRpcProvider | null = null;
    contract: Contract | null = null;
    listeners: string[] = [];

    constructor(contract = 'Tickets', atAddress: string | null = null) {
        let contractData: any;

        if (contract == "ClassicDice") {
            contractData = classicDice;
        } else if (contract == "erc20" && atAddress) {
            contractData = erc20;
        } else {
            contractData = (contractAddresses as any)["thunder_main"]["thundercore"][contract];
        }

        if (!contractData) return;

        this.provider = new providers.JsonRpcProvider(thunderNetwork.rpc);
        this.provider.pollingInterval = 1000;
        this.contract = new Contract(atAddress ? atAddress : contractData.address, contractData.abi, this.provider);
    }

    listen(event: string, fn: providers.Listener, once = false) {
        if (this.contract) {
            this.listeners.push(event);
            if (once)
                return this.contract.once(event, fn);
            else
                return this.contract.on(event, fn);
        }
    }

    clear() {
        if (this.contract) {
            for (const event of this.listeners) {
                this.contract.removeAllListeners(event);
            }
        }
    }
}