import * as ethers from "ethers";
import cookie from "js-cookie";

const enableLocal = false;

interface Data {
    selectedProvider: number;
    walletProviders: string[];
    wallets: {
        provider: string;
        accounts: string[];
        netID: number;
    }[];
}

export default {
    data(): any {
        return {
            selectedProvider: -1,
            walletProviders: [],
            wallets: [],
            detectingWallet: true
        }
    },

    async mounted() {
        const win: any = window;
        const that: any = this;

        const knowProviders = 'thundercore,ethereum,web3'.split(',');

        const chosenProvider = cookie.get("serious-provider");
        const choosenAccountIndex = cookie.get("serious-account-index");

        if (enableLocal) {
            const p = new ethers.providers.JsonRpcProvider("http://localhost:9545");
            try {
                const accounts = await p.listAccounts();
                const netID = (await p.getNetwork()).chainId;
                that.walletProviders.push("local");

                that.wallets.push({ accounts, provider: "local", netID });
            } catch (error) {
                console.log("error ganache", error.toString());
            }
        }

        for (const provider of knowProviders) {
            if (win[provider]) {
                that.walletProviders.push(provider);

                try {
                    let p;
                    if (provider == 'web3') {
                        p = new ethers.providers.Web3Provider(win[provider].currentProvider);
                    } else {
                        p = new ethers.providers.Web3Provider(win[provider]);
                    }
                    const accounts = await p.listAccounts();
                    const netID = (await p.getNetwork()).chainId;

                    that.wallets.push({ accounts, provider, netID });

                    if (accounts[0]) {
                        if (chosenProvider == provider) {
                            (this as any).setActiveProvider(provider, 0, true);
                        }
                    }
                } catch (error) {
                    console.log(`error ${provider}, ${error}`);
                }
            }
        }

        that.detectingWallet = false;

        // if wallets length is 1 and we are having the account, it mean we already loged in
        // thundercorehub
        const withAccounts = that.wallets.filter((v: any) => v.accounts.length > 0);
        const cookieExists = cookie.get("serious-provider");
        if (!cookieExists && withAccounts.length > 0) {
            // already loged in..
            that.setActiveProvider(withAccounts[0].provider, 0);
        }
    },

    methods: {
        setActiveProvider(provider: string, index = 0, monitorEvent = false) {
            const w = (this as any).wallets.filter((v: any) => v.provider == provider);
            const wallet = w[0];
            if (wallet) {
                cookie.set("serious-provider", provider);
                cookie.set("serious-account-index", index.toString());
                (this as any).$store.commit("setProvider", { selectedAccount: wallet.accounts[0], selectedProvider: provider, selectedAccountIndex: index });

                if (monitorEvent && provider !== 'local') {
                    const win = window as any;
                    win[provider].on("accountsChanged", (accounts: any) => {
                        location.reload();
                    })
                    win[provider].on("chainChanged", (chainId: any) => {
                        location.reload();
                    })
                }
            }
        },

        translateProvider(provider: string) {
            switch (provider) {
                case "thundercore": return "ThunderLink";
                case "ethereum": return "MetaMask";
                case "web3": return "Web3";
                case "local": return "Local";
            }
            return "[UNKNOWN] Wallet"
        },

        async loginWeb3(provider: string) {
            const that: Data = this as any;
            const win = window as any;

            if (that.walletProviders.filter(v => v == provider).length == 0) {
                return alert(`wallet provider: ${provider} does not exist`);
            }

            try {
                if (provider != 'web3') {
                    // metamask, thunderlink
                    await win[provider].enable();
                }

                this.setActiveProvider(provider, 0, true);
                return true;
            } catch (error) {
                return false;
            }
        },

        getProvider() {
            const that = this as any;
            let provider;

            if (that.$store.state.selectedAccount && that.$store.state.selectedProvider) {
                if (that.$store.state.selectedProvider === 'local') {
                    provider = new ethers.providers.JsonRpcProvider("http://localhost:9545")
                } else {
                    if (that.$store.state.selectedProvider == 'web3') {
                        provider = new ethers.providers.Web3Provider((window as any)[that.$store.state.selectedProvider].currentProvider)
                    } else {
                        provider = new ethers.providers.Web3Provider((window as any)[that.$store.state.selectedProvider])
                    }
                }
            }

            if (provider)
                provider.pollingInterval = 1000;

            return provider;
        }
    }
}