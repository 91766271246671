export default {
    // which contract definition?
    chain: "thunder_main",
    // which rpc?
    // rpc: "http://127.0.0.1:7545",
    rpc: "https://mainnet-rpc.thundercore.com"
}

if (process.env.NODE_ENV == "production") {
    // network = "thunder_main";
}