import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import tokenDiceTokens from "../views/tokendice-tokens";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/Login.vue")
  },
  // {
  //   path: "/tickets/:contestId?",
  //   name: "Tickets",
  //   component: () =>
  //     import("../views/Tickets.vue")
  // },
  // {
  //   path: "/champions",
  //   name: "Champions",
  //   component: () =>
  //     import("../views/ChampionGame.vue")
  // },
  {
    path: "/dice",
    name: "Dice",
    component: () =>
      import("../views/Dice.vue")
  },
  // {
  //   path: "/classic-dice",
  //   name: "ClassicDice",
  //   component: () =>
  //     import("../views/ClassicDice.vue")
  // },
  // {
  //   path: "/classic-dice-investment",
  //   name: "ClassicDiceInvestment",
  //   component: () =>
  //     import("../views/ClassicDiceInvestment.vue")
  // },
  {
    path: "/dividend",
    name: "Dividend",
    component: () =>
      import("../views/Dividend.vue")
  },
  {
    path: "/referral",
    name: "Referral",
    component: () =>
      import("../views/Referral.vue")
  },
  // {
  //   path: "/farmer",
  //   name: "Farmer",
  //   component: () =>
  //     import("../views/Farmer.vue")
  // },
  // {
  //   path: "/farmer-simulation",
  //   name: "FarmerSimulation",
  //   component: () =>
  //     import("../views/FarmerSimulation.vue")
  // },
  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: () =>
  //     import("../views/Adm.vue")
  // },
  {
    path: "/careful",
    name: "Careful",
    component: () =>
      import("../views/Careful.vue")
  },
  {
    path: "/token-dice/:goTokenId?",
    name: "TokenDice",
    component: () =>
      import("../views/TokenDice.vue")
  },
  {
    path: "/risk",
    name: "Risk",
    component: () =>
      import("../views/RISK.vue")
  },
  {
    path: "/dividend2",
    name: "Dividend2",
    component: () =>
      import("../views/Dividend2.vue")
  },
  // {
  //   path: "/simple-wallet",
  //   name: "SimpleWallet",
  //   component: () =>
  //     import("../views/WalletWithMnemonic.vue")
  // },
  // {
  //   path: "/contract-deployer",
  //   name: "ContractDeployer",
  //   component: () =>
  //     import("../views/ContractDeployer.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name == "TokenDice" && to.params && to.params.goTokenId) {
    const tokens = tokenDiceTokens;
    const goTokenId = to.params.goTokenId;

    const token = tokens.find(
      (v) => v.id == goTokenId
    );

    if (token) {
      localStorage.setItem("TokenDiceTokenID", token.id.toString());
    }

    return next("/token-dice");
  }

  // watching TT at the top menu
  store.dispatch("watchTT");
  return next();
})

router.afterEach((hook) => {
  const jq = (window as any)['jQuery'];
  jq(function ($: any) {
    $('#navbarColor01.collapse').collapse('hide')
  })
})

export default router;
