
import Vue from "vue";
import TopMenu from "@/views/TopMenu.vue";
import FooterSection from "@/views/FooterSection.vue";
import ContractMonitoring from "./contracts/contract-monitoring";

export default Vue.extend({
    components: {
        TopMenu,
        FooterSection,
    },
    mounted() {
        //
    },
});
