
import { BigNumber } from "ethers";
import { Vue, Component, Watch } from "vue-property-decorator";

import Big from "bignumber.js";
import WrongNetwork from "../components/WrongNetwork.vue";

import Login from "./Login.vue";
import { providers } from "ethers";
import { Contract } from "ethers";
import { getContract } from "../contracts/helper";
import thunderNetwork from "@/thunder-network";

Big.config({ EXPONENTIAL_AT: 256 });

let deltaId = 0;

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

@Component({
    components: { Login, WrongNetwork },
})
export default class TopMenu extends Vue {
    balance = new Big(0);
    deltas: { id: number; value: Big }[] = [];
    provider = new providers.JsonRpcProvider(thunderNetwork.rpc);
    erc20Contract: Contract | null = null;
    processWatchBalance: any = null;

    @Watch("$store.state.selectedAccount")
    async onSelectedAccountChanged() {
        this.cleanUp();
        await this.processWatchBalance;
        this.processWatchBalance = this.watchBalance();
    }

    @Watch("$store.state.balanceWatchToken")
    async onTokenChanged() {
        this.cleanUp();
        await this.processWatchBalance;
        this.processWatchBalance = this.watchBalance();
    }

    scrollY = window.scrollY;
    navbarHeight = 0;

    mounted() {
        this.processWatchBalance = this.watchBalance();

        this.navbarHeight = (this.$refs.navBar as HTMLElement).offsetHeight + 2;

        // watch scroll
        window.onscroll = (e: any) => {
            this.scrollY = window.scrollY;
        };
    }

    beforeDestroy() {
        window.onscroll = null;
        this.cleanUp();
    }

    cleanUp() {
        this.provider.removeAllListeners();
        this.erc20Contract?.removeAllListeners("Transfer");
    }

    Big(v: any) {
        return new Big(v.toString());
    }

    // animation of balance addition/removal
    addDelta(prior: Big, current: Big) {
        const delta = current.minus(prior);

        if (delta.abs().decimalPlaces(4).gt(0)) {
            const deltaItem = {
                id: ++deltaId,
                value: delta,
            };

            this.deltas.push(deltaItem);

            // removing deltas
            setTimeout(() => {
                const idx = this.deltas.findIndex(
                    (v: typeof deltaItem) => v.id == deltaItem.id
                );
                if (idx >= 0) {
                    this.deltas.splice(idx, 1);
                }
            }, 2000);
        }
    }

    async watchBalance() {
        const account = this.$store.state.selectedAccount;
        const token = this.$store.state.balanceWatchToken;

        this.balance = new Big(0);

        console.log("watch balance", token.symbol);

        if (account) {
            if (token.symbol == "TT") {
                this.balance = toEther(await this.provider.getBalance(account));
                // monitor balance
                this.provider.on(account, (b: BigNumber) => {
                    const prior = this.balance.times(1);
                    const current = toEther(b);
                    this.balance = current;

                    this.addDelta(prior, current);
                });
            } else {
                this.erc20Contract?.removeAllListeners("Transfer");

                this.erc20Contract = getContract(
                    "ERC20",
                    this.provider,
                    token.address
                );

                this.balance = toEther(
                    await this.erc20Contract.balanceOf(account),
                    token.dp
                );

                this.erc20Contract.on(
                    "Transfer",
                    async (from: string, to: string, amount: BigNumber) => {
                        if (
                            (from == account || to == account) &&
                            this.erc20Contract
                        ) {
                            const a = toEther(amount, token.dp);

                            const prior = this.balance.times(1);

                            const current =
                                from == account
                                    ? this.balance.minus(a)
                                    : this.balance.plus(a);

                            this.balance = current;

                            this.addDelta(prior, current);
                        }
                    }
                );
            }
        }
    }
}
