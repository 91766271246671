import thunderNetwork from '@/thunder-network';
import { Contract, Signer } from 'ethers';
import { providers } from 'ethers';
import contractAddresses from "./contract-addresses.json";
import erc20Abi from "./erc20.json";

export function getContract(contractName: string, ps: providers.Provider | Signer, atAddress = '', network = 'thunder_main', chain = 'thundercore') {
    if (contractName == "ERC20") {
        if (!atAddress) throw 'ERC20 needs address';
        return new Contract(atAddress, erc20Abi, ps);
    }

    const contAddr = contractAddresses as any;
    if (!contAddr[network]) throw `invalid network: ${network}`;
    if (!contAddr[network][chain]) throw `invalid chain: ${chain}`;
    if (!contAddr[network][chain][contractName]) throw `invalid contract name: ${contractName}`;

    const spec = contAddr[network][chain][contractName];

    return new Contract(atAddress ? atAddress : spec.address, spec.abi, ps);
}

export function getProvider(providerName = '', forMonitoring = false): providers.JsonRpcProvider {
    const win = window as any;

    if (forMonitoring) {
        const p = new providers.JsonRpcProvider(thunderNetwork.rpc);
        p.pollingInterval = 1000;
        return p;
    }

    if (!win[providerName]) throw new Error('invalid provider name:' + providerName);

    if (providerName == 'web3') {
        return new providers.Web3Provider(win[providerName].currentProvider);
    } else {
        return new providers.Web3Provider(win[providerName]);
    }
}

export async function getReceiptLogs(txHash: string, event: string, provider?: providers.Provider, contract?: Contract) {
    if (!provider) throw 'could not monitor receipt';
    if (!contract) throw 'could not parse log';

    const r = await provider.waitForTransaction(txHash);
    if (r.status && r.logs) {
        const logs = r.logs.map(v => {
            try {
                return contract.interface.parseLog(v);
            } catch (error) {
                return null;
            }
        }).filter(v => v && v.name == event);

        return {
            receipt: r,
            logs
        };
    } else {
        throw 'transaction failed';
    }
}

export function getEventFromReceipt(receipt: providers.TransactionReceipt, event: string, contract?: Contract) {
    if (!contract) throw 'could not parse log';

    if (receipt.status && receipt.logs) {
        const logs = receipt.logs.map((v: providers.Log) => {
            try {
                return contract.interface.parseLog(v);
            } catch (error) {
                return null;
            }
        }).filter((v) => v && v.name == event);
        return logs;
    } else {
        throw 'transaction failed';
    }
}